/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-fragments */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Loader, Button } from 'lec-ui';
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';
import { api } from 'app/api';

import * as act from 'app/actions';
import * as studentLessonActions from './studentLessonActions';
import messages from './studentLessonMessages';

import * as panelActions from '../panel/panelActions';

import './StudentLesson.scss';
import OnlineClassCard from './OnlineClassCard';
import SimplificaCard from './SimplificaCard';
import LessonFilesCard from './LessonFilesCard';
import UploadFileCard from './UploadFileCard';
import MessageBoxGroupChat from './MessageBoxGroupChat';

const StudentLesson = (props) => {
  const {
    intl,
    user,
    location,
    isLoading,
    getStudentLesson,
    studentLessonData,
    history,
    getScheduledLessonChat,
    addScheduledLessonChatMessage,
    canSendMessages,
    conversationsList,
    messagesList,
    downloadLessonResourseFile,
    deleteScheduledLessonResources,
    managePlanScheduledAnalytics,
    viewLessonResourseFile,
    mediaFile,
  } = props;
  const params = queryString.parse(location.search.replace('?', ''));

  // const [lessonLabel, setLessonLabel] = useState('');
  const [lessonProposalButton, setLessonProposalButton] = useState(false);

  const [onlineClassResources, setOnlineClassResources] = useState({});
  const [simplificaResources, setSimplificaClassResources] = useState({});

  const [lessonFiles, setLessonFiles] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [canSendFiles, setCanSendFiles] = useState(false);

  useEffect(() => {
    getStudentLesson({
      idPlanScheduled: params.idPlanScheduled,
      idLesson: params.idLesson,
      lessonIndex: params.lessonIndex,
    });
  }, []);

  useEffect(() => {
    if (studentLessonData && studentLessonData.canAccess === false) {
      history.push(`/my-lessons?idPlanScheduled=${params.idPlanScheduled}`);
    }

    if (studentLessonData && studentLessonData.canSendFiles !== undefined) {
      setCanSendFiles(studentLessonData.canSendFiles);
    }

    if (studentLessonData.idProposal) {
      setLessonProposalButton(true);

      getScheduledLessonChat({
        idLessonConfiguration: studentLessonData.idProposal,
      });

      if (studentLessonData.resources) {
        if (studentLessonData.resources.online_class) {
          if (studentLessonData.resources.online_class.length > 0) {
            setOnlineClassResources(studentLessonData.resources.online_class[0]);
          }
        }

        if (studentLessonData.resources.simplifica) {
          if (studentLessonData.resources.simplifica.length > 0) {
            setSimplificaClassResources(studentLessonData.resources.simplifica[0]);
          }
        }

        if (studentLessonData.resources.files) {
          const { files } = studentLessonData.resources;
          if (files.lessonFiles && files.lessonFiles.length > 0) {
            setLessonFiles(files.lessonFiles);
          } else {
            setLessonFiles([]);
          }
          if (files.myFiles && files.myFiles.length > 0) {
            setMyFiles(files.myFiles);
          } else {
            setMyFiles([]);
          }
        }
      }
    } else {
      setLessonProposalButton(false);
      setOnlineClassResources({});
      setSimplificaClassResources({});
    }
  }, [studentLessonData]);

  useEffect(() => {
    if (studentLessonData !== null) {
      const interval = setInterval(() => {
        getScheduledLessonChat({
          idLessonConfiguration: studentLessonData.idProposal,
        });
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [studentLessonData]);

  function goBack() {
    history.push(`/my-lessons?idPlanScheduled=${params.idPlanScheduled}`);
  }

  async function handleManagePlanScheduledAnalytics(resourceType = null, idLessonResource = null) {
    managePlanScheduledAnalytics({
      idPlanScheduled: params.idPlanScheduled,
      idLesson: params.idLesson,
      resourceType,
      idLessonResource,
      lessonIndex: params.lessonIndex,
    });
  }

  async function handleViewFile(fileID, type) {
    await viewLessonResourseFile({
      idResource: fileID,
    });
    await handleManagePlanScheduledAnalytics(type, fileID);
  }

  async function handleSendMessage(message, userSelected) {
    await handleManagePlanScheduledAnalytics('chat');
    await addScheduledLessonChatMessage({
      idLessonConfiguration: studentLessonData.idProposal,
      idUser: userSelected.id,
      message,
    });

    await getScheduledLessonChat({
      idLessonConfiguration: studentLessonData.idProposal,
    });
  }

  function handleDownloadFile(fileID, filename, type) {
    handleManagePlanScheduledAnalytics(type, fileID);
    downloadLessonResourseFile({
      idResource: fileID,
      filename,
    });
  }

  async function handleRemoveResources(idResource) {
    await deleteScheduledLessonResources({
      idResource,
    });
    getStudentLesson({
      idPlanScheduled: params.idPlanScheduled,
      idLesson: params.idLesson,
      lessonIndex: params.lessonIndex,
    });
  }

  async function handleResources(resource) {
    if (resource.resourceType === 'file') {
      if (resource.files.length > 0) {
        const formData = new FormData();
        formData.append('resourceType', resource.resourceType);
        resource.files.forEach((file) => {
          formData.append('files[]', file);
        });
        formData.append('idLessonConfiguration', parseInt(studentLessonData.idProposal, 10));

        await api
          .post('/teach/addScheduledLessonResources.php', formData)
          .then(
            () =>
              getStudentLesson({
                idPlanScheduled: params.idPlanScheduled,
                idLesson: params.idLesson,
                lessonIndex: params.lessonIndex,
              }),
            Promise.resolve(),
          )
          .catch((err) => {
            console.error('erro no upload', err.response.data);
          });
      }
    }

    return Promise.resolve();
  }

  return (
    <Fragment>
      <Row>
        <Loader show={isLoading} />
        <Col>
          <div className="title-container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="lesson-date">{studentLessonData.dateLabel}</div>
              <div className="course-name">{studentLessonData.courseName}</div>
            </div>
            <button
              type="button"
              onClick={() => goBack()}
              className="btn btn-light"
              style={{ width: '130px', minWidth: 'inherit' }}
            >
              {intl.formatMessage(messages.back)}
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={7} xl={7} className="mb-0 mb-md-5">
          <div className="lesson-content">
            <Row>
              <Col lg="12">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className={`component-icon ${studentLessonData.componentIcon}`} />
                  <div
                    style={{ fontWeight: 'bold' }}
                    dangerouslySetInnerHTML={{
                      __html: `${
                        `${studentLessonData.label1} - ${studentLessonData.label2}` || ''
                      }`,
                    }}
                  />
                </div>
                <div style={{ marginTop: '15px' }}>{studentLessonData.label3}</div>
                <div
                  style={{ marginTop: '15px' }}
                  dangerouslySetInnerHTML={{ __html: studentLessonData.proposalText }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} md={12} lg={5} xl={5}>
          <div className="title-configure">
            {`${intl.formatMessage(messages.materialOfTheDay)} ${studentLessonData.dateLabel}`}
          </div>
          <div className="resources-container">
            <div className="resources-block mb-3">
              {Object.keys(onlineClassResources).length > 0 && (
                <OnlineClassCard
                  content={onlineClassResources}
                  handleManagePlanScheduledAnalytics={handleManagePlanScheduledAnalytics}
                />
              )}
              {Object.keys(simplificaResources).length > 0 && (
                <SimplificaCard
                  content={simplificaResources}
                  handleManagePlanScheduledAnalytics={handleManagePlanScheduledAnalytics}
                />
              )}
              {lessonFiles.length > 0 && (
                <LessonFilesCard
                  handleDownloadFile={handleDownloadFile}
                  handleViewFile={handleViewFile}
                  content={lessonFiles}
                  handleManagePlanScheduledAnalytics={handleManagePlanScheduledAnalytics}
                  mediaFile={mediaFile}
                />
              )}
              <UploadFileCard
                handleSaveUploadFile={handleResources}
                handleDownloadFile={handleDownloadFile}
                handleRemoveUploadFile={handleRemoveResources}
                content={myFiles}
                canSendFiles={canSendFiles}
                handleManagePlanScheduledAnalytics={handleManagePlanScheduledAnalytics}
                mediaFile={mediaFile}
                handleViewFile={handleViewFile}
              />
            </div>
            {lessonProposalButton !== null && (
              <MessageBoxGroupChat
                sendMessage={(message, userSelected) => handleSendMessage(message, userSelected)}
                canSendMessages={canSendMessages}
                conversationsList={conversationsList}
                messagesList={messagesList}
                courseName={studentLessonData.courseName}
                daySelected={studentLessonData.dateLabel}
                userName={`${user.firstName} ${user.lastName}`}
              />
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

StudentLesson.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getStudentLesson: PropTypes.func.isRequired,
  studentLessonData: PropTypes.any.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  deleteScheduledLessonResources: PropTypes.func.isRequired,
  downloadLessonResourseFile: PropTypes.func.isRequired,
  getScheduledLessonChat: PropTypes.func.isRequired,
  addScheduledLessonChatMessage: PropTypes.func.isRequired,
  canSendMessages: PropTypes.bool.isRequired,
  conversationsList: PropTypes.array.isRequired,
  messagesList: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  managePlanScheduledAnalytics: PropTypes.func.isRequired,
  viewLessonResourseFile: PropTypes.func.isRequired,
  mediaFile: PropTypes.any.isRequired,
};

StudentLesson.defaultProps = {
  intl: [],
};

const mapStateToProps = ({ studentLesson, app, panelActions }) => ({
  ...studentLesson,
  ...app,
  ...panelActions,
});

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, act.promisify({ ...studentLessonActions, ...panelActions })),
)(StudentLesson);
