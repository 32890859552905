import * as act from 'app/actions';
import * as actions from './myPlansActions';

const initialState = {
  myPlans: [],
  deletedPlan: '',
  errorDeletedPlan: '',
  deletedScheduledPlan: '',
  errorDeletedScheduledPlan: '',
  isLoading: false,
  schoolYears: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case act.request(actions.GET_MY_PLANS):
      return {
        ...state,
        isLoading: true,
        myPlans: initialState.myPlans,
      };
    case act.success(actions.GET_MY_PLANS):
      return {
        ...state,
        myPlans: action.payload.content,
        isLoading: initialState.isLoading,
        deletedPlan: initialState.deletedPlan,
        deletedScheduledPlan: initialState.deletedScheduledPlan,
      };
    case act.request(actions.DELETE_PLAN):
      return {
        ...state,
        deletedPlan: initialState.deletedPlan,
        errorDeletedPlan: initialState.errorDeletedPlan,
        isLoading: true,
      };
    case act.failure(actions.DELETE_PLAN):
      return {
        ...state,
        errorDeletedPlan: action.payload.error,
        isLoading: initialState.isLoading,
      };
    case act.success(actions.DELETE_PLAN):
      return {
        ...state,
        deletedPlan: action.payload.success,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.DELETE_SCHEDULED_PLAN):
      return {
        ...state,
        deletedScheduledPlan: initialState.deletedScheduledPlan,
        errorDeletedScheduledPlan: initialState.errorDeletedScheduledPlan,
        isLoading: true,
      };
    case act.failure(actions.DELETE_SCHEDULED_PLAN):
      return {
        ...state,
        errorDeletedScheduledPlan: action.payload.error,
        isLoading: initialState.isLoading,
      };
    case act.success(actions.DELETE_SCHEDULED_PLAN):
      return {
        ...state,
        deletedScheduledPlan: action.payload.success,
        isLoading: initialState.isLoading,
      };
    case act.request(actions.GET_SCHOOL_YEARS):
      return {
        ...state,
        schoolYears: initialState.schoolYears,
        isLoading: true,
      };
    case act.success(actions.GET_SCHOOL_YEARS):
      return {
        ...state,
        schoolYears: action.payload.content,
        isLoading: initialState.isLoading,
      };
    default:
      return state;
  }
};

export default reducer;
