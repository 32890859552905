/* eslint-disable max-lines */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Loader } from 'lec-ui';
import queryString from 'query-string';
import moment from 'moment';

import { Row, Col } from 'react-bootstrap';

import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

import * as act from 'app/actions';
import PanelFilters from 'app/common/filters/panelFiltersContainer';
import messages from './planLessonsMessages';

import * as planLessonsActions from './planLessonsActions';
import * as panelActions from '../panel/panelActions';

import CardMonth from '../calendar/CardMonth';

import './PlanLessons.scss';
import { set } from 'lodash';

const PlanLessonsContainer = (props) => {
  const {
    intl,
    location,
    getFilterCatalogings,
    filters,
    isLoading,
    planLessonsIsLoading,
    getPlanScheduledData,
    lessons,
    history,
    getScheduledPlanCourses,
    scheduledPlanCourses,
    getScheduledLessonsDates,
    scheduledLessonsDates,
    editScheduledLessonStatus,
    segmentFilterType,
  } = props;

  function toDayDate(date) {
    return moment(date).startOf('day').toDate();
  }

  const params = queryString.parse(location.search.replace('?', ''));
  const [idSegment, setIdSegment] = useState(null);
  const [idComponent, setIdComponent] = useState(null);
  const [period] = useState(params.period || 'bimonthly');

  const [idScheduled, setIdScheduled] = useState(null);

  const [idSegmentSelected, setIdSegmentSelected] = useState(null);
  const [idComponentSelected, setIdComponentSelected] = useState(null);
  const [idScheduledSelected, setIdScheduledSelected] = useState(null);

  const [filterSegment, setFilterSegment] = useState([]);
  const [filterComponent, setFilterComponent] = useState([]);
  const [filterScheduledCourse, setFilterScheduledCourse] = useState([]);

  const [currentDate, setCurrentDate] = useState(toDayDate());

  const [progressStatus, setProgressStatus] = useState([]);

  const [newArrayLessons, setNewArrayLessons] = useState([]);

  const planStatus = ['scheduled', 'notApplied', 'applied'];

  const idScheduledAux = useRef(idScheduled);

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const [schools, setSchools] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);

  useEffect(() => {
    (async () => {
      setFilterSegment([]);
      setFilterComponent([]);
      await getFilterCatalogings();
    })();

    getScheduledPlanCourses({
      idPlanSaved: params.planSaved,
    });

    setIdSegmentSelected(params.segment);
    setIdComponentSelected(params.component);

    // clean up on unmount component
    return () => {
      setFilterSegment([]);
      setFilterComponent([]);
    };
  }, []);

  useEffect(() => {
    if (filterSegment.length <= 0 && segmentFilterType) {
      setFilterSegment(filters);
    }
    if (filterSegment.length > 0 && idSegment && !segmentFilterType) {
      setFilterComponent(filters);
    }
  }, [filters]);

  useEffect(async () => {
    if (idSegment && filterSegment.length > 0) {
      await getFilterCatalogings({ idCataloging: idSegment });
    }
  }, [idSegment, filterSegment]);

  useEffect(() => {
    if (scheduledPlanCourses.length > 0) {
      const scheduledPlanCoursesNew = [];
      scheduledPlanCourses.map((course) => {
        scheduledPlanCoursesNew.push({ value: course.idPlanScheduled, label: course.courseName });
      });
      if (params.scheduled) {
        setIdScheduledSelected(params.scheduled);
      } else {
        setIdScheduled(scheduledPlanCoursesNew[0].value);
      }
      setFilterScheduledCourse(scheduledPlanCoursesNew);
    }
  }, [scheduledPlanCourses]);

  useEffect(() => {
    const currentMonthNumber = moment(currentDate).month() + 1;
    const oneMonthNumberFromNow = moment(currentDate).add(1, 'month').month() + 1;
    const twoMonthsNumberFromNow = moment(currentDate).add(2, 'month').month() + 1;

    getScheduledLessonsDates({
      months: [currentMonthNumber, oneMonthNumberFromNow, twoMonthsNumberFromNow],
      ...(params.scheduled
        ? { idPlanScheduled: params.scheduled }
        : { idPlanSaved: params.planSaved }),
    });
  }, [currentDate]);

  function handleStatusProgressBar() {
    const arr = [];

    newArrayLessons.map((lesson, idx) => {
      const count = { 1: 0, 2: 0, 3: 0 };

      lesson.map((item) => {
        const total = lesson.length;
        if (item.status in count) {
          count[item.status]++;
        }

        const percentage = {
          0: parseFloat(((count['1'] / total) * 100).toFixed(1)),
          1: parseFloat(((count['2'] / total) * 100).toFixed(1)),
          2: parseFloat(((count['3'] / total) * 100).toFixed(1)),
        };

        arr[idx] = percentage;
      });
    });

    setProgressStatus(arr);
  }

  useEffect(() => {
    if (lessons.length > 0) {
      setNewArrayLessons(lessons);
    }
  }, [lessons]);

  useEffect(() => {
    if (newArrayLessons.length > 0) {
      handleStatusProgressBar();
    }
  }, [newArrayLessons]);

  useEffect(() => {
    if (idScheduled !== idScheduledAux.current && filterScheduledCourse.length > 0) {
      idScheduledAux.current = idScheduled;
      getPlanScheduledData({
        idPlanScheduled: idScheduled,
        period: params.period || 'bimonthly',
      });
    }
  }, [idScheduled, filterScheduledCourse]);

  function selectDate(date) {
    setCurrentDate(date);
  }

  function nextDate() {
    const nextCurrentDate = moment(currentDate).add(1, 'month').toDate();
    selectDate(nextCurrentDate);
  }

  function previousDate() {
    const nextCurrentDate = moment(currentDate).subtract(1, 'month').toDate();
    selectDate(nextCurrentDate);
  }

  const oneMonthFromNow = moment(currentDate).add(1, 'month').toDate();
  const twoMonthsFromNow = moment(currentDate).add(2, 'month').toDate();

  function handleClassConfiguration(event, idLesson) {
    setFilterSegment([]);
    setFilterComponent([]);

    const path = `/class-configuration?idPlan=${params.idPlan}&planSaved=${params.planSaved}&segment=${params.segment}&component=${params.component}&period=${params.period}&scheduled=${idScheduled}&idLesson=${idLesson}`;
    if (event.ctrlKey || event.metaKey) {
      // Open in a new tab
      window.open(path, '_blank');
    } else {
      // Navigate in the same tab
      history.push(path);
    }
  }

  function handleClassConfigurationDay(event, idLesson, index) {
    setFilterSegment([]);
    setFilterComponent([]);

    const path = `/class-configuration?idPlan=${params.idPlan}&planSaved=${params.planSaved}&segment=${params.segment}&component=${params.component}&period=${params.period}&scheduled=${idScheduled}&idLesson=${idLesson}&lessonIndex=${index}`;
    if (event.ctrlKey || event.metaKey) {
      // Open in a new tab
      window.open(path, '_blank');
    } else {
      // Navigate in the same tab
      history.push(path);
    }
  }

  async function changeButtonStatus(idScheduledLesson, status, idLesson) {
    await editScheduledLessonStatus({
      // idPlanScheduled: params.scheduled,
      idScheduledLesson,
      newStatus: status === '2' || status === '1' ? '3' : '2',
    });

    const newArrayLessonsCopy = [...newArrayLessons];
    const lessonIndex = newArrayLessonsCopy.findIndex((lesson) =>
      lesson.find((item) => item.idLesson === idLesson),
    );
    const lesson = newArrayLessonsCopy[lessonIndex];
    const lessonItemIndex = lesson.findIndex((item) => item.idLesson === idLesson);
    const lessonItem = lesson[lessonItemIndex];
    const lessonItemLessonDays = lessonItem.lessonsDays;
    const lessonItemLessonDaysIndex = lessonItemLessonDays.findIndex(
      (item) => item.idScheduledLesson === idScheduledLesson,
    );
    const lessonItemLessonDaysItem = lessonItemLessonDays[lessonItemLessonDaysIndex];
    lessonItemLessonDaysItem.status = status === '2' || status === '1' ? '3' : '2';

    // if all days are applied, change lesson status to applied
    const allDaysApplied = lessonItemLessonDays.every((day) => day.status === '3');
    const allDaysNotApplied = lessonItemLessonDays.every((day) => day.status === '2');
    if (allDaysApplied) {
      lessonItem.status = '3';
    } else if (allDaysNotApplied) {
      lessonItem.status = '2';
    } else {
      lessonItem.status = '1';
    }

    setNewArrayLessons(newArrayLessonsCopy);
  }

  let label1;
  let label2;
  let abilities;

  useEffect(() => {
    if (selectedYear) {
      sessionStorage.setItem('selectedYear', selectedYear);
    }
  }, [selectedYear]);

  useEffect(() => {
      if (selectedSchool) {
        sessionStorage.setItem('selectedSchool', selectedSchool);
      }
    }, [selectedSchool]);

  useEffect(() => {
    const year = sessionStorage.getItem('selectedYear');

    const yearsArray = [
      {
        value: year,
        label: year,
      },
    ];
    setYears(yearsArray);

    if (year) {
      setSelectedYear(year);
    }

    
    const school = sessionStorage.getItem('selectedSchool');
    if (school) {
      setSelectedSchool(school);
    }

    if(props.user.idSchools) {
      const uniqueSchools = props.user.idSchools.map((school) => ({
        value: school.id,
        label: school.name,
      }));

      setSchools(uniqueSchools);
    }
  }, []);

  async function handleChangeSelectedYear(selectedYearId) {
    setSelectedYear(selectedYearId);
  }


  async function handleChangeSelectedSchool(selectedSchoolId) {
    setSelectedSchool(selectedSchoolId);
  }

  return (
    <Fragment>
      <Row>
        <Loader show={isLoading || planLessonsIsLoading} />
        <Col xs={6} md={6} lg={9} xl={9}>
          <Row className="mb-2">
            <Col>
              <PanelFilters
                idSegment={idSegment}
                idComponent={idComponent}
                idScheduled={idScheduled}
                filterSegment={filterSegment}
                filterComponent={filterComponent}
                filterScheduledCourse={filterScheduledCourse}
                setIdSegment={(value) => setIdSegment(value)}
                setIdComponent={(value) => setIdComponent(value)}
                setIdScheduled={(value) => setIdScheduled(value)}
                idSegmentSelected={idSegmentSelected || params.segment}
                idComponentSelected={idComponentSelected || params.component}
                idScheduledSelected={idScheduledSelected || params.scheduled}
                years={years}
                selectedYear={selectedYear}
                setSelectedYear={(value) => handleChangeSelectedYear(value)}
                schools={schools}
                selectedSchool={selectedSchool}
                setSelectedSchool={(value) => handleChangeSelectedSchool(value)}
                disabled
              />
            </Col>
          </Row>

          <Row className="d-flex flex-column">
            {newArrayLessons &&
              newArrayLessons.length > 0 &&
              newArrayLessons.map((lesson, idx) => (
                <div className="lessons-container" key={idx}>
                  <Col>
                    <Row>
                      <Col md="12" lg="7" className="mb-4">
                        <div className="d-flex title-container">
                          <h3 className="cp-title">
                            {idx + 1}{' '}
                            {period === 'bimonthly' || period === null
                              ? intl.formatMessage(messages.bimonthly)
                              : intl.formatMessage(messages.quarterly)}
                          </h3>
                          <div className="lesson-progressBar d-flex">
                            <div className="progress-label">
                              {intl.formatMessage(messages.progress)}
                            </div>
                            <div className="progress-percent">
                              {progressStatus[idx] && progressStatus[idx]['2']}%
                            </div>
                            <div
                              className="lesson-progressBar__applied"
                              style={{
                                width: `${progressStatus[idx] && progressStatus[idx]['2']}%`,
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="12" lg="5">
                        <div className="progressBar-label mb-md-4">
                          <div className="d-flex align-items-center">
                            <div className="color-applied" />
                            <div>{intl.formatMessage(messages.applied)}</div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="color-notApplied" />
                            <div>{intl.formatMessage(messages.notApplied)}</div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {lesson.map((item, idx2) => (
                      <Row
                        key={`idx2-${idx2}`}
                        className={`d-flex justify-content-between align-items-center lesson-card ${
                          planStatus[item.status - 1] || `notApplied`
                        }`}
                      >
                        <Col md="12" lg="5" className="lc-title mb-md-4 mb-lg-0">
                          {
                            ((label1 = item.values.findIndex((el) => el.variable === 'label1')),
                            label1 &&
                              item.values[label1] &&
                              item.values[label1].showPlanno === '1' && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.values[label1].value || '',
                                  }}
                                />
                              ))
                          }{' '}
                          {' - '}
                          {
                            ((label2 = item.values.findIndex((el) => el.variable === 'label2')),
                            label2 &&
                              item.values[label2] &&
                              item.values[label2].showPlanno === '1' && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.values[label2].value || '',
                                  }}
                                />
                              ))
                          }
                          <br />
                          <div className="dates-block">
                            {item.lessonsDays.map((day, idx4) => (
                              <div className="lessonDaysCard">
                                <button
                                  key={`idx4-${idx4}`}
                                  type="button"
                                  onClick={(event) =>
                                    handleClassConfigurationDay(event, item.idLesson, idx4)
                                  }
                                  className={`btn-proposal ${day.status === '3' && 'active'} ${
                                    day.status !== '3' && day.hasChatMessages && 'hasChatMessages'
                                  }`}
                                >
                                  {intl.formatMessage(messages.class)} {day.date}
                                  {day.hasChatMessages && <i className="ti-comments-smiley" />}
                                </button>
                                <div className="card-menu">
                                  <i className="fas fa-ellipsis-v" />
                                  <div className="cm-floating">
                                    <a
                                      onClick={() =>
                                        changeButtonStatus(
                                          day.idScheduledLesson,
                                          day.status,
                                          item.idLesson,
                                        )
                                      }
                                    >
                                      {day.status === '3'
                                        ? intl.formatMessage(messages.changeStateNotApplied)
                                        : intl.formatMessage(messages.changeStateApplied)}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Col>
                        <Col md="12" lg="2" className="mb-md-4 mb-lg-0">
                          <div className="text-uppercase font-14">
                            {planStatus[item.status - 1] === 'scheduled'
                              ? intl.formatMessage(messages.scheduled)
                              : planStatus[item.status - 1] === 'applied'
                              ? intl.formatMessage(messages.applied)
                              : intl.formatMessage(messages.notApplied)}
                          </div>
                        </Col>
                        <Col md="12" lg="5">
                          <div className="block-label d-flex flex-wrap">
                            {
                              ((abilities = item.values.findIndex(
                                (el) => el.variable === 'abilities',
                              )),
                              abilities &&
                                item.values[abilities] &&
                                item.values[abilities].showPlanno === '1' &&
                                item.values[abilities].value.map((el, idx3) => (
                                  <div
                                    key={`idx3-${idx3}`}
                                    className="label"
                                    dangerouslySetInnerHTML={{ __html: el }}
                                  />
                                )))
                            }
                          </div>
                        </Col>
                        <div className="lesson-card-config">
                          <Tooltip
                            size="small"
                            theme="light"
                            arrow
                            distance="26"
                            html={
                              <span style={{ fontSize: '18px' }}>
                                {intl.formatMessage(messages.configureLessonContent)}
                              </span>
                            }
                          >
                            <i
                              className="icon pe-7s-config"
                              onClick={(event) => handleClassConfiguration(event, item.idLesson)}
                            />
                          </Tooltip>
                        </div>
                      </Row>
                    ))}
                  </Col>
                </div>
              ))}
          </Row>
        </Col>
        <Col xs={6} md={6} lg={3} xl={3} className="calendar-container">
          <button type="button" className="calendar-navButton" onClick={() => previousDate()}>
            <i className="fa fa-angle-up" />
          </button>
          <CardMonth
            month={currentDate.getMonth() + 1}
            year={currentDate.getFullYear()}
            appointments={scheduledLessonsDates}
            onClickDate={selectDate}
          />
          <CardMonth
            month={oneMonthFromNow.getMonth() + 1}
            year={oneMonthFromNow.getFullYear()}
            appointments={scheduledLessonsDates}
            onClickDate={() => selectDate()}
          />
          <CardMonth
            month={twoMonthsFromNow.getMonth() + 1}
            year={twoMonthsFromNow.getFullYear()}
            appointments={scheduledLessonsDates}
            onClickDate={() => selectDate()}
          />
          <button type="button" className="calendar-navButton" onClick={() => nextDate()}>
            <i className="fa fa-angle-down" />
          </button>
        </Col>
      </Row>
    </Fragment>
  );
};

PlanLessonsContainer.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  getFilterCatalogings: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  planLessonsIsLoading: PropTypes.bool.isRequired,
  getPlanScheduledData: PropTypes.func.isRequired,
  lessons: PropTypes.arrayOf(PropTypes.any).isRequired,
  getScheduledPlanCourses: PropTypes.func.isRequired,
  scheduledPlanCourses: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  getScheduledLessonsDates: PropTypes.func.isRequired,
  scheduledLessonsDates: PropTypes.arrayOf(PropTypes.any),
  editScheduledLessonStatus: PropTypes.func.isRequired,
  segmentFilterType: PropTypes.bool.isRequired,
};

PlanLessonsContainer.defaultProps = {
  intl: [],
  scheduledLessonsDates: [],
};

const mapStateToProps = ({ planLessons, panel, app }) => ({
  ...planLessons,
  ...panel,
  user: app.user,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...panelActions, ...planLessonsActions },
      {
        include: [
          'getFilterCatalogings',
          'getPlanScheduledData',
          'getScheduledPlanCourses',
          'getScheduledLessonsDates',
        ],
      },
    ),
  ),
)(PlanLessonsContainer);
