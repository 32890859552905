/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ClassConfiguration.scss';

const LessonPlanning = (props) => {
  const { handlePlanningText, text } = props;
  const [newText, setNewText] = useState(text || '');

  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ align: [] }],
      ['blockquote', 'code-block'],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      ['link', 'image'],

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'link',
    'image',
    'align',
    'color',
    'background',
    'indent',
    'direction',
    'size',
    'script',
    'clean',
    'break', // Add 'break' format for <br> tags
  ];

  function handleData() {
    handlePlanningText(newText);
  }

  function handleChangeText(value) {
    setNewText(value);
  }

  useEffect(() => {
    handleData();
  }, [newText]);

  useEffect(() => {
    setNewText(text);
  }, [text]);

  return (
    <ReactQuill
      theme="snow"
      value={newText}
      onChange={handleChangeText}
      modules={modules}
      formats={formats}
    />
  );
};

LessonPlanning.propTypes = {
  handlePlanningText: PropTypes.func.isRequired,
  text: PropTypes.string,
};

LessonPlanning.defaultProps = {
  text: '',
};

export default compose(injectIntl)(LessonPlanning);
