import * as act from 'app/actions';

export const GET_SCHEDULED_LESSON_DATA = 'class-configuration/GET_SCHEDULED_LESSON_DATA';
export const getScheduledLessonData = (payload) =>
  act.action(GET_SCHEDULED_LESSON_DATA, { payload });

export const MANAGE_SCHEDULED_LESSON_DATA = 'class-configuration/MANAGE_SCHEDULED_LESSON_DATA';
export const manageScheduledLessonData = (payload) =>
  act.action(MANAGE_SCHEDULED_LESSON_DATA, { payload });

export const EDIT_SCHEDULED_LESSON_RESOURCES =
  'class-configuration/EDIT_SCHEDULED_LESSON_RESOURCES';
export const editScheduledLessonResources = (payload) =>
  act.action(EDIT_SCHEDULED_LESSON_RESOURCES, { payload });

export const DELETE_SCHEDULED_LESSON_RESOURCES =
  'class-configuration/DELETE_SCHEDULED_LESSON_RESOURCES';
export const deleteScheduledLessonResources = (payload) =>
  act.action(DELETE_SCHEDULED_LESSON_RESOURCES, { payload });

export const CHANGE_SCHEDULED_LESSON_VISIBILITY =
  'class-configuration/CHANGE_SCHEDULED_LESSON_VISIBILITY';
export const changeScheduledLessonVisibility = (payload) =>
  act.action(CHANGE_SCHEDULED_LESSON_VISIBILITY, { payload });

export const GET_SCHEDULED_LESSON_CHAT = 'class-configuration/GET_SCHEDULED_LESSON_CHAT';
export const getScheduledLessonChat = (payload) =>
  act.action(GET_SCHEDULED_LESSON_CHAT, { payload });

export const ADD_SCHEDULED_LESSON_CHAT_MESSAGE =
  'class-configuration/ADD_SCHEDULED_LESSON_CHAT_MESSAGE';
export const addScheduledLessonChatMessage = (payload) =>
  act.action(ADD_SCHEDULED_LESSON_CHAT_MESSAGE, { payload });

export const DOWNLOAD_LESSON_RESOURCE_FILE = 'class-configuration/DOWNLOAD_LESSON_RESOURCE_FILE';
export const downloadLessonResourseFile = (payload) =>
  act.action(DOWNLOAD_LESSON_RESOURCE_FILE, { payload });

export const ADD_SCHEDULED_LESSON = 'class-configuration/ADD_SCHEDULED_LESSON';
export const addScheduledLesson = (payload) => act.action(ADD_SCHEDULED_LESSON, { payload });

export const GET_SCHEDULED_PLAN_COURSES_HOURS =
  'class-configuration/GET_SCHEDULED_PLAN_COURSES_HOURS';
export const getScheduledPlanCoursesHours = (payload) =>
  act.action(GET_SCHEDULED_PLAN_COURSES_HOURS, { payload });

export const VIEW_LESSON_RESOURCE_FILE = 'student-lesson/VIEW_LESSON_RESOURCE_FILE';
export const viewLessonResourseFile = (payload) =>
  act.action(VIEW_LESSON_RESOURCE_FILE, { payload });

export const RESET_LESSON_DATA = 'class-configuration/RESET_LESSON_DATA';
export const resetLessonData = () => act.action(RESET_LESSON_DATA);
