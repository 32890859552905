/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Modal } from 'lec-ui';
import moment from 'moment';

import './ClassConfiguration.scss';

import messages from './classConfigurationMessages';

const SimplificaCard = (props) => {
  const { intl, handleSaveSimplificaLink, content, handleRemoveUploadFileLink, idCourse } = props;
  const [showSimplificaModal, setShowSimplificaModal] = useState(false);
  const [simplificaInput, setSimplificaInput] = useState('');
  const [emptySimplificaInput, setEmptySimplificaInput] = useState(false);
  const [invalidSimplificaLink, setInvalidSimplificaLink] = useState(false);
  // const [hasSimplificaLink, setHasSimplificaLink] = useState(false);
  const [idResource, setIdResource] = useState(null);
  const [action, setAction] = useState('add');

  const [simplificaURL, setSimplificaURL] = useState('');
  const [teAPIURL, setTeAPIURL] = useState('');

  function validURL(urlString) {
    const urlPattern = new RegExp(
      '^(https?|ftp)://' + // Scheme (http, https, or ftp)
        '(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?))' + // Domain
        '(?::\\d+)?' + // Optional port
        '(?:/?|[/?]\\S+)$',
      'i',
    );

    // https://simplifica.smaprendizagem.com/assessments/U2FsdGVKX1/2g7zF+|vNy/628MsOJKWr8Ep2Gcdwvh8=

    return urlPattern.test(urlString);
  }

  function handleSimplificaModal() {
    setShowSimplificaModal(!showSimplificaModal);
  }

  function handleSimplificaCardLink(e) {
    if (!simplificaInput) {
      setEmptySimplificaInput(true);
    } else {
      setEmptySimplificaInput(false);
    }
    setSimplificaInput(e.target.value);
  }

  useEffect(() => {
    if (!simplificaInput) {
      setEmptySimplificaInput(true);
      setInvalidSimplificaLink(false);
    } else {
      setEmptySimplificaInput(false);
    }
  }, [simplificaInput]);

  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.includes('dev-')) {
      setSimplificaURL('https://dev-simplifica.smbrasil.com.br');
      setTeAPIURL('https://dev-teapi.smbrasil.com.br');
    } else if (currentURL.includes('localhost')) {
      setSimplificaURL('http://localhost:3000');
      setTeAPIURL('https://dev-teapi.smbrasil.com.br');
    } else {
      setSimplificaURL('https://simplifica.smbrasil.com.br');
      setTeAPIURL('https://teapi.smbrasil.com.br');
    }
  }, []);

  function saveSimplificaModal() {
    const validUrl = validURL(simplificaInput);

    if (validUrl) {
      try {
        const payload = {
          link: simplificaInput,
          resourceType: 'simplifica',
          files: [],
          action,
          idResource,
        };

        handleSaveSimplificaLink(payload);
      } finally {
        setInvalidSimplificaLink(false);
        setShowSimplificaModal(false);
      }
    } else {
      setInvalidSimplificaLink(true);
    }
  }

  useEffect(() => {
    if (content.length > 0 && content.find((item) => item.type === 'simplifica')) {
      const simplificaLink = content.find((item) => item.type === 'simplifica');

      // setHasSimplificaLink(true);
      setAction('edit');
      setSimplificaInput(simplificaLink.resource);
      setIdResource(simplificaLink.id);
    } else {
      // setHasSimplificaLink(false);
      setAction('add');
      setSimplificaInput('');
      setIdResource(null);
    }
  }, [content]);

  function handleClose() {
    setShowSimplificaModal(false);
    setInvalidSimplificaLink(false);
  }

  async function removeLink(id) {
    await handleRemoveUploadFileLink(id);
  }

  return (
    <Fragment>
      <button type="button" onClick={() => handleSimplificaModal()} className="card-resource">
        {content && <div className="simplifica-badge">{content.length || 0}</div>}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38.73"
          height="48.413"
          viewBox="0 0 38.73 48.413"
        >
          <path
            id="Caminho_494"
            data-name="Caminho 494"
            d="M19.365,0,0,12.449V35.964L11.066,42.88,30.431,30.431V17.982L27.664,16.6,13.832,24.9V19.365l13.832-8.3,6.916,4.15V33.2L15.215,45.646l4.15,2.766L38.73,35.964V12.449L27.664,5.533,9.682,17.982V30.431l1.383,1.383,15.215-9.683v5.533L11.066,37.347,4.15,33.2V15.215L23.515,2.766Z"
            fill="#9a9a9a"
          />
        </svg>

        {intl.formatMessage(messages.simplifica)}
      </button>

      <Modal
        show={showSimplificaModal}
        onClose={() => handleClose()}
        title={intl.formatMessage(messages.simplifica)}
        // message={intl.formatMessage(messages.confirmRemoveClass)}
        buttons={[
          {
            label: intl.formatMessage(messages.cancel),
            onClick: () => handleClose(),
          },
          {
            label: intl.formatMessage(messages.save),
            onClick: () => saveSimplificaModal(),
            primary: true,
            disabled: emptySimplificaInput,
          },
        ]}
      >
        <div>
          {invalidSimplificaLink && (
            <div className="boxMsg alert alert-danger">
              {intl.formatMessage(messages.enterUrlCorrectly)}
            </div>
          )}

          <div className={`linkBlock ${!content && 'inactive'}`}>
            {content.length > 0 ? (
              <div>
                <div className="table-container">
                  <table className="lessonFilesTable">
                    <thead>
                      <tr>
                        <th>{intl.formatMessage(messages.assessment)}</th>
                        <th>{intl.formatMessage(messages.actions)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {content.map((el, idx) => (
                        <tr>
                          <td>
                            <span className="title">{el.assessmentName || el.resource}</span>
                            <br />
                            {el.scheduleStatus && (
                              <span className="date">
                                {moment(el.scheduleDateStart).format('DD/MM/YYYY')}{' '}
                                {intl.formatMessage(messages.at)}{' '}
                                {moment(el.scheduleTimeStart, 'HH:mm:ss').format('HH:mm')}{' '}
                                {intl.formatMessage(messages.to)}{' '}
                                {moment(el.scheduleDateEnd).format('DD/MM/YYYY')}{' '}
                                {intl.formatMessage(messages.at)}{' '}
                                {moment(el.scheduleTimeEnd, 'HH:mm:ss').format('HH:mm')}
                                {el.scheduleDuration &&
                                  ` ${intl.formatMessage(messages.withDurationOf)} ${moment(
                                    el.scheduleDuration,
                                    'HH:mm:ss',
                                  ).format('HH:mm')}`}
                              </span>
                            )}
                          </td>
                          <td>
                            {el.type === 'simplifica' && (
                              <span>
                                <a href={el.resource} target="_blank" rel="noreferrer">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="16"
                                    width="16"
                                    viewBox="0 0 512 512"
                                    style={{ marginTop: '-3px' }}
                                  >
                                    <path
                                      fill="#fff"
                                      d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"
                                    />
                                  </svg>
                                </a>
                                <i
                                  onClick={() => removeLink(el.id)}
                                  className="fa fa-trash ml-2"
                                  style={{ cursor: 'pointer' }}
                                />
                              </span>
                            )}
                            {el.assessmentName && (
                              <a
                                href={`${teAPIURL}/node/reports/pdf?idAssessment=${el.idAssessment}&locale=pt&version=1`}
                                target="_blank"
                                rel="noreferrer"
                                className="btnAssessment mr-2"
                              >
                                {intl.formatMessage(messages.downloadPDF)}
                              </a>
                            )}
                            {el.assessmentName && !el.scheduleStatus && el.courseCurrentYear && (
                              <a
                                href={`${simplificaURL}/schedule-sm?idAssessment=${el.idAssessment}&idCourse=${idCourse}&status=0`}
                                target="_blank"
                                rel="noreferrer"
                                className="btnAssessment"
                              >
                                {intl.formatMessage(messages.schedule)}
                              </a>
                            )}
                            {el.assessmentName && el.scheduleStatus === '1' && (
                              <a
                                href={`${simplificaURL}/schedule-sm?idAssessment=${el.idAssessment}&idCourse=${idCourse}&status=${el.scheduleStatus}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btnAssessment"
                              >
                                {intl.formatMessage(messages.edit)}
                              </a>
                            )}
                            {el.assessmentName && el.scheduleStatus === '2' && (
                              <a
                                href={`${simplificaURL}/reports-sm?idSchedule=${el.idSchedule}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btnAssessment"
                              >
                                {intl.formatMessage(messages.seeReport)}
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              intl.formatMessage(messages.noLinkUploadedYet)
            )}
          </div>
          <div className="simplifica-input d-flex">
            {intl.formatMessage(messages.copySimplificaLink)}
            <input
              type="text"
              className={invalidSimplificaLink ? 'danger' : ''}
              value={simplificaInput}
              onChange={(e) => handleSimplificaCardLink(e)}
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

SimplificaCard.propTypes = {
  intl: intlShape,
  handleSaveSimplificaLink: PropTypes.func.isRequired,
  content: PropTypes.object,
  handleRemoveUploadFileLink: PropTypes.func.isRequired,
};

SimplificaCard.defaultProps = {
  intl: [],
  content: {},
};

export default compose(injectIntl)(SimplificaCard);
