/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import queryString from 'query-string';
import { Row, Col, Alert } from 'react-bootstrap';

import { Modal, Loader } from 'lec-ui';

import * as act from 'app/actions';
import PanelFilters from 'app/common/filters/panelFiltersContainer';
import { get, set } from 'lodash';
import messages from './myPlansMessages';

import * as myPlansActions from './myPlansActions';
import * as panelActions from '../panel/panelActions';

import MyPlanCard from './MyPlanCard';
// import MyPlanScheduledCourseCard from './MyPlanScheduledCourseCard';

const MyPlansContainer = (props) => {
  const {
    intl,
    location,
    getFilterCatalogings,
    getMyPlans,
    filters,
    myPlans,
    history,
    deletePlan,
    deleteScheduledPlan,
    isLoading,
    deletedPlan,
    errorDeletedPlan,
    deletedScheduledPlan,
    errorDeletedScheduledPlan,
    segmentFilterType,
    getSchoolYears,
    schoolYears,
  } = props;
  const params = queryString.parse(location.search.replace('?', ''));
  const [idSegment, setIdSegment] = useState(null);
  const [idComponent, setIdComponent] = useState(null);
  const [idSegmentSelected, setIdSegmentSelected] = useState(null);
  const [idComponentSelected, setIdComponentSelected] = useState(null);

  const [filterSegment, setFilterSegment] = useState([]);
  const [filterComponent, setFilterComponent] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [alreadyScheduled, setAlreadyScheduled] = useState(false);
  const [classScheduled, setClassScheduled] = useState('');

  const [showDeleteScheduledPlanModal, setShowDeleteScheduledPlanModal] = useState(false);
  const [selectedScheduledPlan, setSelectedScheduledPlan] = useState(null);

  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState('');

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const [schools, setSchools] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);

  function handleEditPlan(planId, componentId, segmentId, idPlan) {
    history.push(
      `/create-plan?idPlan=${idPlan}&planSaved=${planId}&segment=${segmentId}&component=${componentId}`,
    );
  }
console.log(props);

  function handleShowScheduledPlanInfo(
    planSaved,
    segment,
    component,
    period,
    idPlanScheduled,
    idPlan,
  ) {
    history.push(
      `/plan-lessons?idPlan=${idPlan}&planSaved=${planSaved}&segment=${segment}&component=${component}&period=${period}&scheduled=${idPlanScheduled}`,
    );
  }

  function handleDeletePlan() {
    deletePlan({ idPlan: selectedPlan });
  }

  function handleShowModal(idPlan, courseName) {
    if (courseName) {
      setAlreadyScheduled(true);
      setClassScheduled(courseName);
    }
    setShowModal(true);
    setSelectedPlan(idPlan);
  }

  async function handleCloseDeletePlan() {
    setShowModal(false);
    if (idSegment) {
      await getMyPlans({ idSegment, idComponent, year: selectedYear });
    } else {
      await getMyPlans({
        year: selectedYear,
      });
    }
  }

  function handleDeleteScheduledPlan() {
    deleteScheduledPlan({ idScheduledPlan: selectedScheduledPlan });
  }

  function handleShowDeleteScheduledPlanModal(idScheduledPlan) {
    setShowDeleteScheduledPlanModal(true);
    setSelectedScheduledPlan(idScheduledPlan);
  }

  async function handleCloseDeleteScheduledPlan() {
    setShowDeleteScheduledPlanModal(false);
    if (idSegment) {
      await getMyPlans({ idSegment, idComponent });
    } else {
      await getMyPlans({
        year: selectedYear,
      });
    }
  }

  function handleOpenComments(comments) {
    setComments(comments);
    setShowComments(true);
  }

  function handleCloseComments() {
    setShowComments(false);
    setComments('');
  }

  useEffect(() => {
    (async () => {
      await getFilterCatalogings();
      // await getMyPlans({
      //   year: selectedYear,
      // });
      await getSchoolYears();

      setIdSegmentSelected(params.segment);
      setIdComponentSelected(params.component);
    })();

    // clean up on unmount component
    return () => {
      setFilterSegment([]);
      setFilterComponent([]);
    };
  }, []);

  useEffect(() => {
    if (filterSegment.length <= 0 && segmentFilterType) {
      setFilterSegment(filters);
    }
    if (filterSegment.length > 0 && idSegment && !segmentFilterType) {
      setFilterComponent(filters);
    }
  }, [filters]);

  useEffect(async () => {
    if (idSegment && filterSegment.length > 0) {
      await getFilterCatalogings({ idCataloging: idSegment });
    }
  }, [idSegment, filterSegment]);

  useEffect(async () => {
    if (idComponent && idSegment) {
      await getMyPlans({ idSegment, idComponent, year: selectedYear });
    }
  }, [idSegment, idComponent]);

  useEffect(() => {
    if (schoolYears && schoolYears.length > 0) {
      const uniqueYears = schoolYears.map((year) => ({
        value: year,
        label: year,
      }));
      setYears(uniqueYears);
      setSelectedYear(selectedYear || uniqueYears[0].value);
    }
  }, [schoolYears]);

  useEffect(() => {
    if (selectedYear) {
      sessionStorage.setItem('selectedYear', selectedYear);

      getMyPlans({
        year: selectedYear,
      });
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedSchool) {
      sessionStorage.setItem('selectedSchool', selectedSchool);
    }
  }, [selectedSchool]);


  useEffect(() => {
    const year = sessionStorage.getItem('selectedYear');
    if (year) {
      setSelectedYear(year);
    }

    const school = sessionStorage.getItem('selectedSchool');
    if (school) {
      setSelectedSchool(school);
    }

    if(props.user.idSchools) {
      const uniqueSchools = props.user.idSchools.map((school) => ({
        value: school.id,
        label: school.name,
      }));

      setSchools(uniqueSchools);
    }
  }, []);

  async function handleChangeSelectedYear(selectedYearId) {
    setSelectedYear(selectedYearId);

    await getMyPlans({
      year: selectedYearId,
    });
  }

  async function handleChangeSelectedSchool(selectedSchoolId) {
    setSelectedSchool(selectedSchoolId);
  }

  return (
    <Fragment>
      <Loader show={isLoading} />
      <Modal
        show={showDeleteScheduledPlanModal}
        icon="pe-7s-trash"
        onClose={() => setShowDeleteScheduledPlanModal(false)}
        title={intl.formatMessage(messages.removeScheduledPlan)}
        message={intl.formatMessage(messages.removeScheduledPlanText)}
        buttons={
          deletedScheduledPlan
            ? [
                {
                  label: intl.formatMessage(messages.close),
                  onClick: () => handleCloseDeleteScheduledPlan(),
                },
              ]
            : [
                {
                  label: intl.formatMessage(messages.cancel),
                  onClick: () => setShowDeleteScheduledPlanModal(false),
                },
                {
                  label: intl.formatMessage(messages.confirm),
                  onClick: () => handleDeleteScheduledPlan(),
                },
              ]
        }
      >
        {errorDeletedScheduledPlan && (
          <Alert variant="danger">{intl.formatMessage(messages.genericError)}</Alert>
        )}
        {deletedScheduledPlan && (
          <Alert variant="success">
            {intl.formatMessage(messages.deletedScheduledPlanWithSuccess)}
          </Alert>
        )}
      </Modal>
      <Modal
        show={showModal}
        icon="pe-7s-trash"
        onClose={() => setShowModal(false)}
        title={intl.formatMessage(messages.removePlan)}
        message={
          alreadyScheduled
            ? `${intl.formatMessage(messages.removePlanTextScheduled)} ${classScheduled}.`
            : intl.formatMessage(messages.removePlanText)
        }
        buttons={
          deletedPlan
            ? [
                {
                  label: intl.formatMessage(messages.close),
                  onClick: () => handleCloseDeletePlan(),
                },
              ]
            : [
                {
                  label: intl.formatMessage(messages.cancel),
                  onClick: () => setShowModal(false),
                },
                {
                  label: intl.formatMessage(messages.confirm),
                  onClick: () => handleDeletePlan(),
                },
              ]
        }
      >
        {errorDeletedPlan && (
          <Alert variant="danger">{intl.formatMessage(messages.genericError)}</Alert>
        )}
        {deletedPlan && (
          <Alert variant="success">{intl.formatMessage(messages.deletedWithSuccess)}</Alert>
        )}
      </Modal>
      <Modal
        show={showComments}
        icon="pe-7s-comment"
        onClose={() => handleCloseComments()}
        title={intl.formatMessage(messages.comment)}
        message={comments}
        buttons={[
          {
            label: intl.formatMessage(messages.close),
            onClick: () => handleCloseComments(),
          },
        ]}
      />
      <Row>
        <Col>
          <PanelFilters
            idSegment={idSegment}
            idComponent={idComponent}
            filterSegment={filterSegment}
            filterComponent={filterComponent}
            setIdSegment={(value) => setIdSegment(value)}
            setIdComponent={(value) => setIdComponent(value)}
            idSegmentSelected={idSegmentSelected}
            idComponentSelected={idComponentSelected}
            years={years}
            selectedYear={selectedYear}
            setSelectedYear={(value) => handleChangeSelectedYear(value)}
            schools={schools}
            selectedSchool={selectedSchool}
            setSelectedSchool={(value) => handleChangeSelectedSchool(value)}
          />
          <div className="cp-container">
            <div>
              <h3 className="cp-title">{intl.formatMessage(messages.title)}</h3>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {myPlans.length > 0
            ? myPlans.map((plan, idx) => (
                <MyPlanCard
                  key={`${plan.id}-${idx}`}
                  plan={plan}
                  editPlan={(planId, componentId, segmentId, idPlan) =>
                    handleEditPlan(planId, componentId, segmentId, idPlan)
                  }
                  deletePlan={(idPlan, courseName) => handleShowModal(idPlan, courseName)}
                  handleShowScheduledPlanInfo={(
                    planSaved,
                    segment,
                    component,
                    period,
                    idPlanScheduled,
                    idPlan,
                  ) =>
                    handleShowScheduledPlanInfo(
                      planSaved,
                      segment,
                      component,
                      period,
                      idPlanScheduled,
                      idPlan,
                    )
                  }
                  handleShowDeleteScheduledPlanModal={(idScheduledPlan) =>
                    handleShowDeleteScheduledPlanModal(idScheduledPlan)
                  }
                  viewScheduledPlan
                  handleOpenComments={(comments) => handleOpenComments(comments)}
                />
              ))
            : intl.formatMessage(messages.noPlansFound)}
        </Col>
      </Row>
    </Fragment>
  );
};

MyPlansContainer.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getFilterCatalogings: PropTypes.func.isRequired,
  getMyPlans: PropTypes.func.isRequired,
  filters: PropTypes.any.isRequired,
  myPlans: PropTypes.any.isRequired,
  deletePlan: PropTypes.func.isRequired,
  deleteScheduledPlan: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  deletedPlan: PropTypes.string.isRequired,
  errorDeletedPlan: PropTypes.string.isRequired,
  deletedScheduledPlan: PropTypes.string.isRequired,
  errorDeletedScheduledPlan: PropTypes.string.isRequired,
  segmentFilterType: PropTypes.bool.isRequired,
  getSchoolYears: PropTypes.func.isRequired,
  schoolYears: PropTypes.any.isRequired,
};

MyPlansContainer.defaultProps = {
  intl: [],
};

const mapStateToProps = ({ myPlans, panel, app }) => ({
  ...myPlans,
  ...panel,
  user: app.user,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...myPlansActions, ...panelActions },
      {
        include: ['getMyPlans', 'getFilterCatalogings', 'deletePlan', 'deleteScheduledPlan'],
      },
    ),
  ),
)(MyPlansContainer);
