import * as act from 'app/actions';

export const GET_MY_PLANS = 'my-plans/GET_MY_PLANS';
export const DELETE_PLAN = 'my-plans/DELETE_PLAN';
export const DELETE_SCHEDULED_PLAN = 'my-plans/DELETE_SCHEDULED_PLAN';
export const GET_SCHOOL_YEARS = 'my-plans/GET_SCHOOL_YEARS';

export const getMyPlans = (payload) => act.action(GET_MY_PLANS, { payload });
export const deletePlan = (payload) => act.action(DELETE_PLAN, { payload });
export const deleteScheduledPlan = (payload) => act.action(DELETE_SCHEDULED_PLAN, { payload });
export const getSchoolYears = (payload) => act.action(GET_SCHOOL_YEARS, { payload });
